import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth";
import loader from "@/store/modules/loader";
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentRoute: null,
    loadingInProgress: {
      nothing: false,
      global: false,
      auth: false,
      panel: false,
      permList: false,
      userActivities: false,
      userDomains: false,
      userRequests: false,
      userTickets: false,
      userTransactions: false,
      userSessions: false,
      ticket: false,
      ticketUserProducts: false,
    },
    notify:{
      active: false,
      code: "",
      color: "",
      icon: "",
      text: ""
    },
    countryList: [
      {
        "id": 108,
        "name": "Iran (Islamic Republic of)",
        "nativeName": "ایران",
        "callingCodes": "+98",
        "flag_name": "irn.svg",
        "flag_file": "https://cdn-frf.vsabt.com/flags/irn.svg"
      }
    ],
    isRouting: false,
  },
  getters:{
    isLoadingInProgress: (state) => (state.loadingInProgress),
    notification: (state) => (state.notify),
    currentRoute: (state) => (state.currentRoute)
  },
  actions: {
    setCurrentRoute: async ({ commit }, route) => {
      await commit('_setCurrentRoute', route)
      return true
    },
    apiCaller: async ({state, commit, dispatch}, opt) => {

      commit("_setLoading", {type:opt.callerID, s:true})
      try {
        let Header = {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }
        if (opt.formType){
          Header["Content-type"] = opt.formType
        }
        if (opt.data.token){
          Header['Authorization'] = `Bearer ${opt.data.token}`
        }
        if (opt.authReq){
          const token = Vue.$cookies.get('VSABTAUTH')
          Header['Authorization'] = `Bearer ${token}`
        }
        let init = {}
        if(opt.reqType.toLowerCase() === 'post'){
          init = {
            url: opt.url,
            method: 'POST',
            headers: Header,
            data: opt.formType==='multipart/form-data'? opt.data : JSON.stringify(opt.data),
          }
        }
        else if(opt.reqType.toLowerCase() === 'get'){
          init = {url: opt.url, method: 'GET', headers: Header,}
        }
        else if(opt.reqType.toLowerCase() === 'delete'){
          init = {
            url: opt.url,
            method: 'DELETE',
            headers: Header,
          }
        }
        const res = await axios(init)
        commit("_setLoading", {type:opt.callerID, s:false})
        return await res.data
      } catch (e) {
        if (e.response.data.code !== 401) {
          commit('_throwConnectionError', {type:opt.callerID, error:e})
          if (e.response.data.code === 403){
            await router.push({name: 'Dashboard'})
          }
        } else {
          if (state.currentRoute.name !== 'Login' && state.currentRoute.path !== '/' ){
            commit('_throwConnectionError', {type:opt.callerID, error:e})
          }
        }
      }
      commit("_setLoading", {type:opt.callerID, s:false})
    },
    notify: function ({ commit}, data) {
      commit('_notifySend', {message: data.message, type:data.type})
    }
  },
  mutations: {
    _setCurrentRoute: (state, route) => (state.currentRoute = route),
    _setLoading: (state, opts) => (state.loadingInProgress[opts.type] = opts.s),
    _closeNotification: (state) => (state.notify.active = false),
    _throwConnectionError: async (state, opt) => {
      state.loadingInProgress[opt.type] = false
      if (opt.error.response) {
        state.notify.code = opt.error.response.data.code
        state.notify.color = 'warning'
        state.notify.text = opt.error.response.data.message
        if (opt.error.response.data.code === 403 || opt.error.response.data.code === 401) {
          state.notify.icon = 'account-question-outline'

        } else {
          state.notify.icon = 'progress-alert'
        }
      }
      else{
        state.notify.code = 500
        state.notify.color = 'warning'
        state.notify.icon = 'wifi-strength-off-outline'
        state.notify.text = "خطا در اتصال"
      }
      state.notify.active = true
    },
    _notifySend: (state, opts) =>{
      state.loadingInProgress[opts.type] = false
      state.notify.color = opts.message.code === 200 ? 'success': 'error'
      state.notify.icon = opts.message.code === 200 ? 'check-bold' : 'close-thick'
      state.notify.code = opts.message.code
      state.notify.text = opts.message.text
      state.notify.active = true
    },
  },
  modules: {
    auth,
    loader,
  }
})
