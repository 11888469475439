import Vue from 'vue'
import store from "@/store";
import VueRouter from 'vue-router'
import Login from "@/views/auth/Login";
import NotFound from "@/views/error/404"
import Forbidden from "@/views/error/403"
import ServerError from "@/views/error/500"

Vue.use(VueRouter)


const routes = [
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "panel" */ '../views/admin/Admin'),
        children: [
            // DASHBOARD ROUTES =================================================================================
            {path: '', redirect: 'dashboard'},
            {
                path: '',
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard/Dashboard'),
                children: [
                    {
                        path: 'dashboard',
                        name: 'Dashboard',
                        component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard/DashboardContent'),
                        meta: {permission: 10000, isChild: false, menuID: 1, activate: false}
                    },
                ]
            },

            // USER MANAGEMENT ROUTES ===========================================================================
            {
                path: 'user-management',
                component: () => import(/* webpackChunkName: "user-management" */ '../views/admin/UserManagement/UserManagement.vue'),
                meta: {permission: 20000, isChild: false},
                children: [
                    {path: '', name: 'UserManagement', redirect: 'all-users'},
                    {
                        path: 'all-users',
                        name: 'AllUsers',
                        component: () => import(/* webpackChunkName: "user-list" */ '../views/admin/UserManagement/Users/AllUsers.vue'),
                        meta: {permission: 21000, isChild: true, menuID: 2, activate: false},
                    },
                    {
                        path: 'managers-list',
                        name: 'ManagersList',
                        component: () => import(/* webpackChunkName: "managers-list" */ '../views/admin/UserManagement/Managers/ManagersList.vue'),
                        meta: {permission: 22000, isChild: true, menuID: 2, activate: false}
                    },
                    {
                        path: 'role-management',
                        name: 'RoleManagement',
                        component: () => import(/* webpackChunkName: "role-management" */ '../views/admin/UserManagement/Roles/RoleManagement.vue'),
                        meta: {permission: 23000, isChild: true, menuID: 2, activate: false}
                    },
                ]
            },
            {
                path: 'user-management/user/:username',
                name: 'UserInfo',
                props: true,
                component: () => import(/* webpackChunkName: "user_info" */ '../views/admin/UserManagement/Users/SingleUserDetails/SingleUserDetails.vue'),
                meta: {permission: 21004, isChild: false, menuID: 2, activate: false},
            },

            // SUPPORT ROUTES ===================================================================================
            {
                path: 'support',
                component: () => import(/* webpackChunkName: "user-management" */ '../views/admin/Support/Support.vue'),
                meta: {permission: 30000, isChild: false},
                children: [
                    {path: '', name: 'Support', redirect: 'tickets'},
                    {
                        path: 'tickets',
                        name: 'Tickets',
                        component: () => import(/* webpackChunkName: "user-list" */ '../views/admin/Support/Tickets/Tickets.vue'),
                        meta: {permission: 31000, isChild: true, menuID: 3, activate: false},
                    },
                    {
                        path: 'announcements',
                        name: 'Announcements',
                        component: () => import(/* webpackChunkName: "user-list" */ '../views/admin/Support/Announcements/Announcements.vue'),
                        meta: {permission: 32000, isChild: true, menuID: 3, activate: false},
                    },
                ]
            },
            {
                path: 'support/ticket/new',
                name: 'NewTicket',
                component: () => import(/* webpackChunkName: "user-list" */ '../views/admin/Support/Tickets/NewTicket.vue'),
                meta: {permission: 31001, isChild: true, menuID: 3, activate: false},
            },
            {
                path: 'support/ticket/:id',
                name: 'TicketDetails',
                props: true,
                component: () => import(/* webpackChunkName: "user_info" */ '../views/admin/Support/Tickets/TicketDetails.vue'),
                meta: {permission: 31004, isChild: false, menuID: 3, activate: false},
            },

            // REQUEST MANAGEMENT ROUTES =========================================================================
            {
                path: 'request-management',
                component: () => import(/* webpackChunkName: "user-management" */ '../views/admin/RequestManagement/RequestManagement.vue'),
                meta: {permission: 40000, isChild: false},
                children: [
                    {path: '', name: 'RequestManagement', redirect: 'requests'},
                    {
                        path: 'requests',
                        name: 'ProductRequests',
                        component: () => import(/* webpackChunkName: "user-list" */ '../views/admin/RequestManagement/ProductRequests/ProductRequests.vue'),
                        meta: {permission: 41000, isChild: true, menuID: 4, activate: false},
                    },
                ]
            },

            {
                path: 'mrp-settings',
                component: () => import(/* webpackChunkName: "mrp_settings" */ '../views/admin/SystemSettings/MRPSettings/MRPSettings'),
                meta: {permission: 74000, isChild: false},
                children: [
                    {path: '', name: 'MRPSettings', redirect: 'managers-list'},

                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: Forbidden
    },
    {
        path: '/error',
        name: 'Error',
        component: ServerError
    },
    {path: '/:pathMatch(.*)*', component: Forbidden},

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({y: 0}),
    routes
})

router.beforeEach(async (to, from, next, redirect) => {
    const _ = await store.dispatch('setCurrentRoute', to)
    const authRes = await store.dispatch('authChecker')
    const userPerms = store.getters.userPermissions

    if (from.name !== "Login" && to.name !== "Forbidden") {
        if (to.name) {
            if (authRes) {
                if (!!userPerms.length){
                    if (to.name === 'Login') {
                        await router.push({name: 'Dashboard'})
                    } else {
                        if (to.meta.permission) {
                            if (userPerms.includes(to.meta.permission)) {
                                await next()
                            } else {
                                await router.push({name: 'Forbidden'})
                            }
                        } else {
                            await next({name: 'Login'})
                        }
                    }
                } else {
                    await router.push({name: 'Forbidden'})
                }
            } else {
                if (to.name === 'Login') {
                    await next()
                } else {
                    await router.push({name: 'Login'})
                }
            }
        } else {
            await next({name: 'Login'})
        }
    }
    else next()
    return false

})

export default router
