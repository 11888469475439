import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import titleMixin from './mixins/titleMixin'
import '@/assets/css/root.css'

window.axios = axios
Vue.config.productionTip = false
Vue.mixin(titleMixin)
Vue.use(VueCookies)

const numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

const NumberToPersianWord = require('number_to_persian_word');
Vue.filter("convertNumber", function (value) {
  return NumberToPersianWord.convert(value) + " تومان";
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
