<template>
    <div class="error-wrapper">
        <v-container tag="section" class="d-flex fill-height">
            <v-row class="top" align="center" justify="center">
                <v-col cols="12" lg="5" xl="4" class="d-flex flex-row justify-center">
                    <router-link :to="{name: 'Home'}"><div class="logo2 d-block d-lg-none"></div></router-link>
                </v-col>
                <v-col cols="12" lg="6" xl="4" offset-xl="1" class="d-flex flex-row justify-end">
                    <router-link :to="{name: 'Home'}"><div class="logo2 d-none d-lg-block"></div></router-link>
                </v-col>
            </v-row>
            <v-row class="middle" align="center" justify="center">
                <v-col cols="12" lg="6" xl="4" offset-xl="1" order="1" order-lg="2">
                    <div class="error-banner">صـــفـحــــــــــــه<br>مـورد نظر شما<br>را پیدا نکردیم</div>
                </v-col>
                <v-col cols="12" lg="5" xl="4" order="2" order-lg="1" id="subscribeWrapper">
                    <v-lazy :options="{ threshold: 0.4 }" transition="scroll-y-transition">
                        <div class="error-code">404</div>
                    </v-lazy>
                    <v-card class="" elevation="5" rounded="xl" id="email-card">

                        <v-card-text>
                            <v-row align="center" class="mx-0">
                                <div class="box-text-error">
                                    لطفاً آدرس صفحه درخواستی خود را بررسی کنید. در صورتی که از صحت آدرس اطمینان دارید یا از طریق یکی از پیوند های وی ثبت به این صفحه هدایت شده&zwnj;اید لطفاً مراتب را با پشتیبانی فنی در میان بگذارید
                                </div>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="pa-5">
                            <v-row>
                                <v-col cols="6">
                                    <v-btn block x-large rounded text color="secondary"><v-icon left>mdi-headset</v-icon>پشتیبانی</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn block x-large rounded text color="secondary" @click="$router.go(-1)">بازگشت<v-icon right >mdi-arrow-left</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="bottom" align="center" justify="center">
                <v-col cols="12" lg="5" xl="4" class="d-flex flex-row justify-center" dir="ltr" order="2" order-lg="1">
                    <span class="cpr grey--text">&copy; 2022 VSABT. All rights reserved.</span>
                </v-col>
                <v-col cols="12" lg="6" xl="4" offset-xl="1" order="1" order-lg="2" >
                    <div class="footer-social">
                        <a href="https://wa.me/message/ZDH3SLOUE6WVG1" id="whatsappLink" target="_blank">
                            <v-btn icon x-large color="success">
                                <v-icon color="green">mdi-whatsapp</v-icon>
                            </v-btn>
                            <v-tooltip color="success" activator="#whatsappLink" top><span>واتس اپ</span></v-tooltip>
                        </a>
                        <a href="https://t.me/vsabt" id="telegramLink" target="_blank">
                            <v-btn icon x-large color="info">
                                <v-icon color="info">mdi-telegram</v-icon>
                            </v-btn>
                            <v-tooltip color="info" activator="#telegramLink" top><span>تلگرام</span></v-tooltip>
                        </a>
                        <a href="https://www.instagram.com/vsabt_com/" target="_blank" id="instagramLink">
                            <v-btn icon x-large color="pink">
                                <v-icon color="pink">mdi-instagram</v-icon>
                            </v-btn>
                            <v-tooltip color="pink" activator="#instagramLink" top><span>اینستاگرام</span></v-tooltip>
                        </a>
                        <a href="https://github.com/VSABT" id="githubLink" target="_blank">
                            <v-btn icon x-large color="black">
                                <v-icon color="black">mdi-github</v-icon>
                            </v-btn>
                            <v-tooltip color="black" activator="#githubLink" top><span>گیت هاب</span></v-tooltip>
                        </a>
                        <a href="https://www.linkedin.com/company/72431853/" target="_blank" id="linkedinLink">
                            <v-btn icon x-large color="indigo lighten">
                                <v-icon color="indigo lighten">mdi-linkedin</v-icon>
                            </v-btn>
                            <v-tooltip color="indigo lighten" activator="#linkedinLink" top><span>لینکدین</span></v-tooltip>
                        </a>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>

    import { mapActions } from "vuex";

    export default {
        title: "یافت نشد",
        name: 'NotFound',
        data: () => ({

        }),
        methods: {
            ...mapActions(["setLoadingScreen"])
        },
        mounted(){
            setTimeout(() => {
                this.setLoadingScreen(false)
            },1000)
        }
    }
</script>

<style scoped>
    .error-wrapper{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-size: auto 100%;
        background: url("~@/assets/images/svg/404bg.svg") no-repeat 70% 50%;
    }

    .logo1 {
        display: block;
        background: url('~@/assets/images/svg/logow2.svg') no-repeat center;
        background-size: contain;
        width: 200px;
        height: 44px;
        mix-blend-mode: overlay;
    }

    .logo2 {
        display: block;
        background: url('~@/assets/images/svg/logoc2.svg') no-repeat center;
        background-size: contain;
        width: 200px;
        height: 44px;
        mix-blend-mode: multiply;
    }

    .error-banner{
        font-family: var(--font_text_1) !important;
        font-weight: bold;
        font-size: 5rem;
        color: var(--c_gray_2);
        mix-blend-mode: luminosity;
        text-shadow: var(--shadow_1);
        text-align: center;
        margin: 30px 0 100px;
    }

    .error-code {
        display: block;
        text-align: center;
        color: var(--c_secondary);
        text-shadow: var(--shadow_2);
        font-size: 15rem;
        font-weight: bold;
        line-height: 4rem;
        margin-bottom: -10px;
    }

    #subscribeWrapper{
        position: relative;
    }

    .box-text-error{
        position: relative;
        text-align: center;
        font-family: var(--font_text_1);
        font-weight: 300;
        color: var(--c_gray_5);
        padding: 0 50px;
        line-height: 2rem;
        margin: 40px auto 10px;
    }

    .submit-btn{
        font-size: 1rem !important;
        font-weight: bold !important;
        letter-spacing: normal !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .footer-social{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 30px;
    }

    .footer-social a{
        text-decoration: none;
        font-size: 2rem;
        color: var(--c_gray_2) !important;
        transition: var(--transition);
    }

    .footer-social a:hover{
        color: var(--c_indigo) !important;
    }

    .cpr{
        mix-blend-mode: multiply;
    }


    @media (max-width: 600px) {
        .error-banner{
            font-size: 3.6rem;
        }
        .error-code {
            font-size: 13rem;
        }
    }

</style>
