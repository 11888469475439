import Vue from 'vue'
import router from '../../router'

const state = {
    userAuthenticated: false,
    user: {},
    ticketUnreads: 0,
    messages: {
        count: 0,
        unread: 0,
        items:[]
    },
    announcementPopup: false,
    credentials:{
        type:'',
        username:'',
        email: '',
        phone: '',
        password:'',
        newPassword:'',
        firstName:'',
        lastName: '',
        country: '',
        uniqueCode: '',
        token: ''
    },
    permissions: []
};

const getters = {
    userInfo: (state) => (state.user),
    userPermissions: (state) => (state.permissions),
    loginStatus: (state) => (state.userAuthenticated),
    userMessages: (state) => (state.messages),
    initCredentials: (state) => (state.credentials),
    countries: (state) => (state.countryList),
    ticketUnreadCount: (state) => (state.ticketUnreads),
};

const actions = {
    login: async ({ commit, dispatch }, info) => {
        const url = '/auth/login'
        const result = await dispatch('apiCaller', {url: url, data:info.credentials, reqType:'POST', callerID:'auth', authReq: true})
        if (result) {
            if (result.code !== 200) {
                const message = {code: result.code, text: result.message}
                commit('_notifySend', {message: message, type:'auth'})
            } else {
                const user = {
                    firstName: result.data.admin.firstName,
                    lastName: result.data.admin.lastName,
                    email: result.data.admin.email,
                    phone: result.data.admin.phone,
                    roles: result.data.admin.roles,
                    avatar: result.data.admin.avatar? result.data.user.avatar : require('@/assets/images/users/default.jpg'),
                    hasAvatar: !!result.data.admin.avatar,
                    token: result.token,
                    permissions: result.data.admin.permissions,
                }
                const message = {code: result.code, text: result.message}
                commit("_updateUserInfo", user)
                commit("_updateUserStatus", true)
                commit("_updateUserPermissions", user.permissions)
                commit("_notifySend", {message: message, type:'auth'})
                commit("_setCookieAuth", result.token)

                const unreadTicketCount = result.data.tickets.unread
                dispatch("setUnreadTicketCount", unreadTicketCount)

                setTimeout(() => {
                    commit("_closeNotification")
                    commit("_clearCredentials")
                    if(!!!info.ref){
                        router.push({name: 'Dashboard'})
                    } else{
                        router.push({name: info.ref})
                    }
                }, 1000)
                return true
            }
        }
        return false
    },
    logOut: async ({ commit, dispatch }) => {
        const token = Vue.$cookies.get('VSABTAUTH')
        const url = '/auth/logout'
        const result = await dispatch('apiCaller', {url: url, data: {token: token}, reqType:'POST', callerID:'auth', authReq: true})
        if (result) {
            if (result.code === 200) {
                const user = {}
                commit("_updateUserInfo", user)
                commit("_updateUserPermissions", [])
                commit("_updateUserStatus", false)
                commit("_setLoading", false)

                const message = {
                    code : result.code,
                    text: result.message,
                }

                commit('_unsetCookieAuth')
                commit('_notifySend', {message: message, type:'auth'})

                return true
            }
        }
        return false
    },
    authChecker: async ({ commit, dispatch }, isLogin=false) => {
        const token = Vue.$cookies.get('VSABTAUTH')
        const url = '/auth'
        const data = token == null? {}:{token:token}
        const result = await dispatch('apiCaller', {url: url, data: data, reqType:'GET', callerID:'auth', authReq:(token!=null)})
        let notifyResult = {message: {active: true,}, type: 'panel'}
        if(result){
            if(result.code === 200) {
                const user = {
                    ...result.data.admin,
                    avatar: result.data.admin.avatar? result.data.admin.avatar : require('@/assets/images/users/default.jpg'),
                    hasAvatar: !!result.data.admin.avatar,
                }
                commit("_updateUserStatus", true)
                commit("_updateUserInfo", user)
                commit("_updateUserPermissions", user.permissions)
                // dispatch("getStatus")

                const unreadTicketCount = result.data.tickets.unread
                dispatch("setUnreadTicketCount", unreadTicketCount)

                return true
            }
            else if (result.code === 401) {
                Object.assign(notifyResult.message,{code: result.code, color: "error", icon: "account-question-outline", text: result.message})
                dispatch('notify', notifyResult)
            }
            else if (result.code === 403) {
                commit('_throwConnectionError', {type:opt.callerID, error:e})
                try {
                    await router.push({name: 'Dashboard'})
                } catch (ee){}

            } else{
                commit('_throwConnectionError', {type:opt.callerID, error:e})
            }
            return false
        }
    },
    editUserInfo: ({ commit }, userData) => {
        if (!userData.avatar){
            userData.avatar = require('@/assets/images/users/default.jpg')
        }
        commit("_editUserInfo", userData)
    },
    updateUserAvatar: ({ commit }, opts) => {
        commit("_updateUserAvatar", {avatarUrl: opts.url, hasAvatar: !opts.isDelete})
    },
    setFieldVerifiedStatus: ({ commit }, opts) => {
        commit("_updateFieldVerificationStatus", {field: opts.field, status: opts.status})
    },
    clearCredentials: ({ commit }) => {
        commit("_clearCredentials")
    },
    getCountryList: async ({ commit, dispatch }) => {
        const url = '/tools/countries/short'
        const result = await dispatch('apiCaller', {url: url, data: {}, reqType:'GET', callerID:'auth', authReq: true})
        if(result && result.code === 200){
            const list = result.data
            commit("_loadCountries", list)
            return list
        } else{
            const message = {code: result.code, text: result.message}
            commit("_notifySend", {message: message, type:'auth'})
        }
    },
    getStatus: async ({ commit, dispatch, state }) => {
        if(state.userAuthenticated){
            try {
                const url = '/user/panel/stat'
                // const result = await dispatch('apiCaller', {url: url, data: {}, reqType: 'GET', callerID: 'auth', authReq: true})
                // if (result && result.code === 200) {
                //     commit("_addNewMessages", {messages: result.data.announcement.messages, unread: result.data.announcement.count})
                //     commit("_updateMessages")
                //
                //     commit("_updateUnreadTicketCount", result.data.ticket.count)
                //
                //     dispatch("updateUserBalance", result.data.finance.balance)
                // } else {
                //     const message = {code: result.code, text: result.message}
                //     commit("_notifySend", {message: message, type: 'auth'})
                // }
            } catch (e) {}
        }
    },
    hasPermissions: async ({ state }, perms) =>{
        let permitted = {...perms}
        Object.keys(perms).forEach(p => {
            if(state.permissions.includes(parseInt(p))){
                permitted[p] = true
            }
        })

        return permitted
    },
    markMessageAsRead: ({ commit }, id) => {
        commit("_markMessageAsRead", id)
        commit("_updateMessages")
    },
    setUnreadTicketCount: ({ commit }, count) => {
        commit("_updateUnreadTicketCount", count)
    }
};

const mutations = {
    _updateCredentials: (state, credentials) => {
        // state.loadingInProgress.auth = false
        Object.assign(state.credentials, credentials)
    },
    _clearCredentials: (state) => (
        state.credentials = {
            type:'', username:'', email: '', phone: '', password:'', newPassword:'',
            firstName:'', lastName: '', country: '', uniqueCode: '', token: ''
        }),
    _authStageManager: (state, stage) => {
        // state.loadingInProgress.auth = false
        state.stage = stage
    },
    _setCookieRegister: (state) => {
        Vue.$cookies.set("accessToken",state.credentials.accessToken,"7d",'/','api.vsabt.com',true,'none');
    },
    _setCookieAuth: (state, cookie) => {
        Vue.$cookies.set("VSABTAUTH", cookie,"365d");
    },
    _unsetCookieAuth: (state) => {
        Vue.$cookies.set("VSABTAUTH", null,"0s");
    },
    _updateUserInfo: (state, userData) => {
        state.user = userData
    },
    _updateUserPermissions: async (state, permissions) => {
        state.permissions = permissions
    },
    _editUserInfo: (state, userData) => {
        state.user = Object.assign(state.user, userData)
    },
    _updateUserAvatar: (state, opts) => {
        state.user.avatar = opts.avatarUrl
        state.user.hasAvatar = opts.hasAvatar
    },
    _updateFieldVerificationStatus: (state, opts) => {
        state.user.verified[opts.field] = opts.status
    },
    _updateUserStatus: (state, userStatus) => {
        state.userAuthenticated = userStatus
    },
    _addNewMessages: (status, newMessages) => {
        status.messages.items = newMessages.messages
        status.messages.unread = newMessages.unread
    },
    _updateMessages: (status) => {
        status.messages.count = status.messages.items.length
    },
    _markMessageAsRead: (state, id) => {
        state.messages.items.forEach((item, index) => {
            if(item.id === id){
                // state.messages.items[index].read = true
                state.messages.items.splice(index, 1)
            }
        })
    },
    _loadCountries: (state, list) =>{
        state.countryList = [...list]
    },
    _updateUnreadTicketCount: (status, count) => {
        status.ticketUnreads = count
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
