<template>
    <div class="login-bg-wrapper">
        <v-container tag="section" class="d-flex fill-height">
            <v-row class="fill-height" no-gutters align="center" justify="center">
                <v-col cols="12" sm="7" md="5" lg="4" id="loginWrapper">
                    <v-lazy :options="{ threshold: 0.4 }" transition="scroll-y-transition">
                        <div class="box-image"></div>
                    </v-lazy>
                    <v-card rounded="xl" elevation="5" :loading="isLoadingInProgress.auth">
                        <template v-slot="progress">
                            <v-progress-linear color="primary" absolute height="4" indeterminate reversed></v-progress-linear>
                        </template>
                        <v-fade-transition>
                            <v-overlay v-if="isLoadingInProgress.auth" absolute color="#ffffff">
                                <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
                            </v-overlay>
                        </v-fade-transition>
                        <v-card-text>
                            <v-row align="center" class="mx-0">
                                <div class="box-text">کاربر گرامی به وی ثبت خوش آمدید</div>
                            </v-row>
                        </v-card-text>
                        <v-card-text class="pa-6 left-text">
                            <v-form ref="loginForm" lazy-validation @submit.prevent="do_login">
                                <v-text-field v-model="credentials.username" rounded outlined filled
                                              :rules="[rules.requiredUsername]" autocomplete="username"
                                              @blur="$refs.loginForm.resetValidation()" autofocus class="text-left"
                                              label="نام کاربری"></v-text-field>
                                <v-text-field v-model="credentials.password" rounded outlined filled type="password"
                                              :rules="[rules.requiredPassword]" autocomplete="password"
                                              @blur="$refs.loginForm.resetValidation()"
                                              label="گذرواژه"></v-text-field>
                                <v-btn rounded block depressed x-large type="submit"
                                       class="login-btn white--text">
                                    ورود
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                            </v-form>
                        </v-card-text>

                    </v-card>
                    <div class="copy-right">
                        <div class="grey--text text--lighten-1" dir="ltr">&copy; 2022 VSABT. All rights reserved.</div>
                    </div>
                </v-col>
            </v-row>
            <v-snackbar v-model="message.active" :color="message.color" top rounded absolute :multi-line="true">
                <v-icon class="ml-1">mdi-{{message.icon}}</v-icon>
                {{ message.text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="light" text v-bind="attrs" @click="message.active = false">بستن</v-btn>
                </template>
            </v-snackbar>
        </v-container>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import verificationMixin from "@/mixins/verificationMixin";

export default {
    title:'ناحیه کاربری',
    name: "Login",
    data() {
        return {
            credentials: null,
            rules: {
                requiredUsername: value => !!value || 'نام کاربری وارد نشده است',
                requiredPassword: value => !!value || 'گذرواژه وارد نشده است'
            },
            message: {active: false, code: "", color: "", icon: "", text: ""}
        }
    },
    mixins:[verificationMixin],
    computed: mapGetters(["initCredentials", "notification", "isLoadingInProgress", "authStage", "loginStatus", "countries"]),
    methods: {
        ...mapActions([
            "authChecker", "resendCode",
            "setStage", "InitializeAuth",
            "verification", "register",
            "login", "initializeResetPass",
            "resetPassword", "setLoadingScreen",
            "getCountryList", "clearCredentials"
        ]),
        do_login: async function () {
            const query = this.getUrlParams(this)
            let ref = null
            if(Object.keys(query).length !== 0){
                if (query.hasOwnProperty('ref')){
                    ref = query.ref
                }
            }
            const data = {
                username: this.credentials.username,
                password: this.credentials.password,
            }
            if (this.$refs.loginForm.validate()) {
                await this.login({credentials: data, ref: ref})
            }
        },
    },
    async beforeRouteEnter(to, from, next){
        next(vm => {
            vm.prevRoute = from
        })
    },
    async created() {
        this.credentials = this.initCredentials
        this.message = this.notification
    },
    async mounted() {
        // await this.authChecker()
        //
        // if (this.loginStatus) {
        //     await this.$router.push({name: "Dashboard"})
        // }
        // this.setStage('init')
        // await setTimeout(async () => {
        //     await this.setLoadingScreen(false)
        // }, 500)
    },
}
</script>

<style scoped>
.login-bg-wrapper{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: url("~@/assets/images/svg/adminloginbg.svg") no-repeat center center;
    background-size: cover;

}

#loginWrapper{
    position: relative;
}

.box-image {
    position: absolute;
    display: block;
    width: 100%;
    height: 120px;
    background: url("~@/assets/images/svg/logoc1.svg") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
    z-index: 999;
    top: -45px;
}

.box-text{
    position: relative;
    text-align: center;
    font-family: var(--font_text_1);
    font-weight: 600;
    color: var(--c_gray_5);
    padding: 0 50px;
    line-height: 2rem;
    margin: 100px auto 20px;
}

.copy-right {
    display: flex;
    flex-direction: row;
    justify-items: center;
    direction: ltr;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: .9rem;
}

.copy-right div {
    flex: 1 0 auto;
}

.login-btn{
    background: var(--c_gradient_1);
}
</style>

<style>
.left-text .v-input__slot input {
    direction: ltr;
}
</style>