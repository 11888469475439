const state = {
    loadingCompleted: false,
    currentSelectedMenuParent: 0,
    globalSettings: {
        showLoadingScreen: true,
        menuItems: [
            {id:1, permission:10000, label:'داشبورد', icon:'view-dashboard-outline', target: {name: 'Dashboard'}, submenu:[]},
            {id:2, permission: 20000, label:'کاربران', icon:'account-group-outline', target:{name: 'UserManagement'}, submenu:[
                    {id:1, permission: 21000, label:'کاربران سیستم', value:'AllUsers', icon:'account-details-outline', badge: false, target: {name: 'AllUsers'}},
                    {id:2, permission: 22000, label:'مدیران سیستم', value:'ManagersList', icon:'account-tie-outline', badge: false, target: {name: 'ManagersList'}},
                    {id:3, permission: 23000,label:'مدیریت نقش ها', value:'RoleManagement', icon:'account-star-outline', badge: false, target: {name: 'RoleManagement'}},
                ]
            },
            {id:3, permission: 30000, label:'پشتیبانی', icon:'headset', target:{name: 'Support'}, submenu:[
                    {id:1, permission:31000, label: 'تیکت ها', icon:'ticket-outline', target:{name:'Tickets'}, relative:true},
                    {id:2, permission:32000, label: 'اطلاعیه ها', icon:'bell-outline', target:{name:'Announcements'}, relative:true},
                ]
            },
            {id:4, permission:40000, label:'درخواست ها', icon:'account-file-text-outline', target:{name:'RequestManagement'}, submenu:[
                    {id:1, permission:41000, label:'درخواست های سیستم', icon:'account-file-text-outline', target:{name:'ProductRequests'}, relative:true},
                    {id:2, permission:42000, label:'تماس با ما', icon:'phone-outline', target:'#', relative:true},
                    {id:3, permission:43000, label:'گزارش قیمت', icon:'account-alert-outline', target:'#', relative:true},
                ]
            },
            {id:5, permission:50000, label:'امور مالی', icon:'finance', target:'#', submenu:[
                    {id:1, permission:51000, label:'تراکنش ها', icon:'cash-check', target:'#', relative:true},
                    {id:2, permission:52000, label: 'تخفیف ها', icon:'tag-multiple-outline', target:'#', relative:true},
                ]
            },
            {id:6, permission:60000, label:'محصولات', icon:'cube-outline', target:'#', submenu:[
                    {id:1, permission:61000, label:'همه پسوند ها', icon:'web', target:'#', relative:true},
                    {id:2, permission:62000, label: 'ثبت کننده ها', icon:'domain', target:'#', relative:true},
                    {id:3, permission:63000, label: 'درخواست ها', icon:'reload-alert', target:'#', relative:true},
                ]
            },
            {id:7, permission:70000, label:'تنظیمات سیستم', icon:'cog-outline', target:'#', submenu:[
                    // {id:1, permission:71000, label:'عمومی', icon:'tune-variant', target:'#', relative:true},
                    // {id:2, permission:72000, label: 'دامنه ها', icon:'web', target:'#', relative:true},
                    // {id:3, permission:73000, label:'درگاه ها', icon:'bank-outline', target:'#', relative:true},
                    {id:4, permission:74000, label:'مجوزها', icon:'file-certificate-outline', target:{name: 'MRPSettings', params:{userPerms: [], childPerms:[74101, 74201]}}, relative:true},
                ]
            },
        ],
        specialBottomNotice:{
            show: false,
            closed: true,
            content: "وی ثبت برای ارائه خدمات بهتر به شما کاربر گرامی از کوکی های مرورگر استفاده میکند",
            buttons:[
                {id:1, text:'متوجه شدم', color:'secondary', action:'close', target:''},
                {id:2, text:'اطلاعات بیشتر', color:'info', action:null, target:'/privacy-policy'}
            ],
            image: require('@/assets/images/svg/illustrations/cookie.svg'),
            duration:-1,
            color:"blue-grey darken-4",
        }
    }
};

const getters = {
    loadingScreen: (state) => (state.globalSettings.showLoadingScreen),
    panelPageLoaded: (state) => (state.loadingCompleted),
    currentSelectedMenuParent: (state) => (state.currentSelectedMenuParent),
    navMenuItems: (state) => (state.globalSettings.menuItems),
    navMenuSubItems: (state) => ((navItemId)=> state.globalSettings.menuItems.filter(item => item.id === navItemId)[0].submenu),
    specialBottomNotice: (state) => (state.globalSettings.specialBottomNotice),
};

const actions = {
    SpecialBottomNoticeIntersectHandler: ({ dispatch, state }) => {
        if(!state.globalSettings.specialBottomNotice.closed){
            dispatch('toggleSpecialBottomNotice')
        }
    },
    closeSpecialBottomNotice: ({ commit }) => {
        commit("_closeSpecialBottomNotice")
    },
    toggleSpecialBottomNotice: ({ commit }) => {
        commit("_toggleSpecialBottomNotice")
    },
    setLoadingScreen: ({ commit }, s) => {
        commit("_setLoadingScreen", s)
    },
    setCurrentSelectedMenuParent: ({ commit }, pid) =>{
        commit("_setCurrentSelectedMenuParent", pid)
    },
    setPanelAuthenticationResult: ({ commit }, status) => {
        commit("_setPanelAuthenticationResult", status)
    },
    setMenuUserPermissions: ({ state,commit }, permissions) => {
        let items = [...state.globalSettings.menuItems]
        items.forEach(item => {
            if (item.hasOwnProperty('submenu') && !!item.submenu){
                item.submenu.forEach(si => {
                    if (typeof si.target === 'object' && si.target !== null && si.target.hasOwnProperty('params')){
                        if (typeof si.target.params === 'object' && si.target.params !== null && si.target.params.hasOwnProperty('userPerms')){
                            si.target.params.userPerms = permissions
                        }
                    }
                })
            }
        })
        commit('_setMenuUserPermissions', items)
        return true
    }
};

const mutations = {
    _closeSpecialBottomNotice: (state) => {
        state.globalSettings.specialBottomNotice.show = false
        state.globalSettings.specialBottomNotice.closed = true
    },
    _setCurrentSelectedMenuParent: (state, pid) => {state.currentSelectedMenuParent = pid},
    _setLoadingScreen: (state, s) => {state.globalSettings.showLoadingScreen = s},
    _toggleSpecialBottomNotice: (state) => (state.globalSettings.specialBottomNotice.show = !state.globalSettings.specialBottomNotice.show),
    _setPanelAuthenticationResult: (state, status) => (state.loadingCompleted = status),
    _setMenuUserPermissions: (state, modifiedMenuItems) => (state.globalSettings.menuItems = modifiedMenuItems)
};

export default {
    state,
    getters,
    actions,
    mutations
};
