import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fa from 'vuetify/es5/locale/fa'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
    rtl: true,
    lang: {
        locales: { fa },
        current: 'fa'
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: '#4e1778',
                secondary: '#B1005B',
            }
        }
    }
})
